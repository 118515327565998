import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'index',
		component: () => import('../views/index.vue')
	},
	{
		path: '/data_index',
		name: 'data_index',
		component: () => import('../views/data_index.vue')
	},
	{
		path: '/data_screen',
		name: 'data_screen',
		component: () => import('../views/data_screen.vue')
	}
]
const router = new VueRouter({
	mode: "history",
	routes
})

export default router
